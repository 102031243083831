#fadein1 {
  animation: fade-in-down 2s;
}

#fadein2 {
  animation: fade-in-up 3s;
}

#photo-fadein {
  animation: fade-in-opacity 1.2s;
}

.fadein3secs {
  animation: fade-in-opacity 3s;
}

.fadein3secshalf {
  animation: fade-in-opacity-half 3s;
}

#fadein3 {
  visibility: hidden;
  animation: show-element 0s 2.5s forwards
}

#fadein4 {
  animation: fade-in-up 1.5s;
  animation-delay: 2.5s;
}

#fadein5 {
  animation: fade-in-up 2.25s;
  animation-delay: 2.5s;
}

@keyframes fade-in-opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in-opacity-half {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes show-element {
  to {
    visibility: visible;
  }
}