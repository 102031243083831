.project-link {
    overflow: hidden;
    aspect-ratio: 1 / 0.7;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
}

.project-link .image-box {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.project-link .image-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 90%;
    border-radius: inherit;
    transition: 0.5s ease-in-out;
}

.project-link::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    opacity: 0;
    transition: 0.4s ease-in-out;
}

.project-link:hover img {
    filter: grayscale(1) brightness(0.4);
    transform: scale(1.2);
}

.project-link:hover::after {
    opacity: 1;
    inset: 20px;
}

.content {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.content h2,
.content p {
    opacity: 0;
    transition: 0.4s 0.2s ease;
}

.content h2 {
    margin-bottom: 12px;
    scale: 0.7;
}

.content p {
    font-size: 14px;
    line-height: 1.5;
    color: #d1d1d1;
    transform: translateY(50%);
}

.project-link:hover .content h2 {
    scale: 1;
    opacity: 1;
}

.project-link:hover .content P {
    opacity: 1;
    transform: translateY(0);
}

#skillBoxText {
    transition: transform 0.3s ease-in-out;
    transform: scale(1);
}

#skillBoxText:hover {
    transition: transform 0.3s ease-in-out;
    transform: scale(1.2);
}
